import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import { AuthContext } from '../context/Context';
import { Redirect, useLocation } from 'react-router-dom';

import loadable from '@loadable/component';
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));

const Layout = () => {
  useEffect(() => {
    AuthCardRoutes.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/auth" component={AuthCardRoutes} />
        <Route path="/errors" component={ErrorLayout} />
        <PrivateRoute component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ ...rest }) {
  let location = useLocation();

  // Context
  const {loggedInUser} = useContext(AuthContext);

  // User logged in? We go to the route
  if(loggedInUser) {
    return (
      <Route {...rest} />
    )
  }

  // Default : we redirect to the authentification page
  // We give the originally requested page in state.redirectUrl, which can be accessed
  // via props.location.redirectUrl in a component
  return (
    <Redirect to={{ pathname: "/auth/login", state: {redirectUrl: location }}} />
  )
}

export default Layout;
